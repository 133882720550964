.specSkuContainer {
	display: grid;
	grid-template-columns: 4fr 4fr;
	column-gap: 50px;
	grid-template-columns: repeat(4, 1fr);
	gap: 10px;
	.specSkuItem {
		padding: 5px;
		// grid-gap: 10px;
		// display: grid;

		// margin-bottom: 10px;
		// .specSkuItemLabel {
		// 	display: flex;
		// 	align-items: center;
		// 	justify-content: flex-end;
		// 	font-size: 14px;
		// 	font-weight: 500;
		// 	color: #333;
		// }
		// .specSkuItemValue {
		// 	display: flex;
		// 	align-items: center;
		// 	justify-content: flex-start;
		// 	font-size: 14px;
		// 	font-weight: 500;
		// 	color: #333;
		// }
	}
}
.specSkuTitle {
	margin-top: 20px;
}
.skuHint {
	font-size: small;
}
