.mainContainer {
	background-color: red;
	width: 100%;
	height: 100%;
}

.mapContainer {
	background-color: #EAE6E0;
	width: 100%;
	height: 100%;
}


.bpContainer {
	width: 40px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.circle {
		width: 40px;
		height: 40px;
		background-color: #FED650;
		border-radius: 40px;
		border: 2px solid black;
	}

	.line {
		width: 2px;
		height: 20px;
		background-color: black;
	}
}