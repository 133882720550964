.list-item {
	list-style: none;
	margin: 1.2em 0;
	background-color: rgba(199, 209, 218, 0.5);
	border-radius: 5px;
	display: flex;
	align-items: center;
	padding: 1.2em 1.5em;

	svg {
		color: #f55321;

		&:first-child {
			font-size: 1.2em;
			margin-right: 0.8em;
		}
	}

	p {
		flex: 1;
		font-size: 0.9em;
	}

	.actions {
		justify-self: flex-end;
		cursor: pointer;

		.fa-spinner {
			font-size: 1.2em;
		}
	}
}
