// .mapContainer {
// 	width: 100%;
// 	height: 100%;
// 	overflow: hidden;
// }

.bMapPreview {
	width: 3rem;
	height: 3rem;
	border-radius: 0.375rem;
	border: 1px solid rgba(0, 0, 0, 0.05);
	overflow: hidden;
	pointer-events: none !important;
	cursor: none !important;

	* {
		pointer-events: none !important;
		cursor: none !important;
	}
}

.mapPreview {
	width: 20rem;
	height: 20rem;
	border-radius: 0.375rem;
	border: 1px solid rgba(0, 0, 0, 0.05);

	&.noRoute {
		overflow: hidden;
		background-color: rgba(0, 0, 0, 0.1);
	}
}

.inputLabel {
	margin: 0 !important;
	font-size: 0.8rem;
	opacity: 0.7;
}

.label {
	margin: 0 !important;
	font-size: 1rem;
}

.adornmentActions {
	display: flex;
	align-items: center;
	gap: 0.5rem;

	.checkIcon {
		color: green;
		font-size: 1.5rem;
	}
}

.innerContainer {
	display: flex;
	width: 100%;
	gap: 1rem;

	.formContainer {
		display: flex;
		flex-direction: column;
		flex: 1;
		gap: 0.5rem;

		.splitInputContainer {
			display: flex;
			gap: 0.5rem;
			align-items: center;

			.verticalLine {
				margin-left: 0.5rem;
				margin-right: 0.5rem;
				border: 0;
				height: 100%;
				width: 1px;
				border-right: 1px solid rgba(0, 0, 0, 0.1);
			}

			.spacedContainer {
				flex: 1;
			}
		}

		.spacedContainer {
			flex: 1;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.value {
				margin: 0 !important;
				font-weight: 700;
			}
		}
	}
}