.logo-brik {
	width: auto !important;
	height: 3rem !important;
	object-fit: contain !important;
	border-radius: 0.25rem;
}

.input-bg {
	background-color: #f8f9fb;
}

.bg-none {
	background: none !important;
}

.logo-brik-text {
	width: auto !important;
	height: 1rem !important;
	object-fit: contain !important;
}

.polygon-fill {
	fill: #ed110d;
}

.bg-default-red {
	background-color: #ed110d;
}

.bg-default {
	background-color: #fcfdfe !important;
}

.h-100 {
	height: 100%;
}

.otp-style {
	width: 100px;

	input {
		width: 35px !important;
		height: 40px !important;
	}
}

.otp-container {
	div {
		justify-content: space-around;
	}
}

.resend-otp {
	color: #ed110d;
	cursor: pointer;
}

.absolute-back {
	position: absolute;
	color: black;
	top: 20px;
	left: 15px;
}

.absolute-back:visited,
.absolute-back:link {
	color: black;
}

.absolute-back:hover {
	color: black;
	cursor: pointer;
}

.customer-gradient-red {
	background: linear-gradient(87deg, #f1211e 0, #800e0c 100%) !important;
}

.navbar-logo {
	height: 100px !important;
	max-height: unset !important;
}

.card-body {
	padding: 1rem;
}

.b-pic {
	p {
		margin: 0;
		font-size: 0.8rem;
	}

	.b-pic-name {
		line-height: 0.9rem;
		color: black;
		font-weight: bold;
		align-items: center;
		display: flex;
	}

	.b-pic-position {
		font-size: 0.6rem;
		background-color: black !important;
		color: white;
		margin-left: 0.5rem;
	}

	.b-pic-data {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
}

.b-competitor {
	p {
		margin: 0;
		font-size: 0.8rem;
	}

	.b-competitor-name {
		line-height: 0.9rem;
		color: black;
		font-weight: bold;
		align-items: center;
		display: flex;
	}
}

.b-back-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0.5rem;
	border-radius: 0.5rem;
	background-color: white;
	cursor: pointer;
}

.file-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;

	&:not(:last-child) {
		margin-bottom: 0.5rem;
	}

	.file-text {
		margin: 0;
		font-size: 0.8rem;
		margin-right: 0.5rem;
	}
}

.b-address-card {
	p {
		margin: 0;
		font-size: 0.8rem;
	}

	.b-address-card-line {
		line-height: 0.9rem;
		color: black;
		font-weight: bold;
		align-items: center;
		display: flex;
	}

	.b-address-card-coord {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;

		.b-address-card-coords {
			font-size: 0.7rem;
			margin-left: 0.5rem;
			margin-right: 0.5rem;
		}
	}
}

.b-image-list {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;

	.card-photo {
		width: 8rem;
		height: 8rem;
		border-radius: 0.5rem;
		border: 1px solid #0000000d;
		overflow: hidden;
		position: relative;
		cursor: pointer;

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}

		.overlay {
			background-color: #00000080;
			z-index: 20;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				opacity: 1;
			}
		}
	}
}

.form-text {
	display: flex;
	align-items: center;
	justify-content: space-between;

	p {
		margin: 0;
	}

	.display-text {
		font-size: 0.9rem;
		opacity: 0.7;
	}

	.display-data {
		font-size: 0.9rem;
		color: black;
		// opacity: 0.7;
	}

	.card-photo {
		width: 100px;
		height: auto;
		border-radius: 0.5rem;
		border: 1px solid #0000000d;
		overflow: hidden;
		position: relative;
		cursor: pointer;

		img {
			object-fit: contain;
			width: 100%;
			height: 100%;
		}

		.overlay {
			background-color: #00000080;
			z-index: 20;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			&:hover {
				opacity: 1;
			}
		}
	}

	&.route {
		&:not(:last-child) {
			margin-bottom: 0.5rem;
		}

		.display-text {
			font-weight: 600;
		}

		.display-text-time {
			font-weight: 600;
			font-size: 0.7rem;
		}

		.left-text {
			display: flex;
			flex-direction: column;
		}

		.route-gmap-btn {
			cursor: pointer;

			.route-gmap-title {
				font-weight: 600;
				opacity: 0.5;
				font-size: 0.7rem;
				margin-right: 0.5rem;
			}
		}
	}
}

.break-line {
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.break-line-v {
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	border: 0;
	border-left: 1px solid rgba(0, 0, 0, 0.1);
	height: 100%;
	width: 1px;
	min-height: 1rem;
}

.spacer-v {
	min-width: 1rem;
}

#loading {
	display: inline-block;
	width: 20px;
	height: 20px;
	border: 3px solid rgba(255, 255, 255, 0.3);
	border-radius: 50%;
	border-top-color: #fff;
	animation: spin 1s ease-in-out infinite;
	-webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}

@-webkit-keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}

.customer-gradient-red {
	background: linear-gradient(87deg, #f1211e 0, #800e0c 100%) !important;
}

.navbar-logo {
	height: 100px !important;
	max-height: unset !important;
}

// stop flashing dropdown when click
.dropdown-item {
	background-color: white !important;
}

.dropdown-item.active,
.dropdown-item:active {
	background-color: white !important;
}

// .confirm-button {
//   border-radius: 0.375rem;
//   color: white;
//   border: 1px solid black;
// }
// .approve-button {
//   background-color: #2dce89;
//   border: 0;
// }
// .decline-button {
//   border: 0;
//   background-color: #f5365c;
// }
.inner-btn {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.dropdown-min {
	.dropdown-menu {
		min-width: 300px;
	}
}

.modal-custom-footer {
	display: flex;
	justify-content: flex-end;
	gap: 15px;
}

.btn-dialog {
	display: flex;
	justify-content: center;
}

.input-style {
	display: flex;
	flex-direction: column;
}

.modal-content {
	width: unset !important;
	max-width: 80% !important;
}

.modal-fieldarray {
	display: flex;
	// items center
	align-items: flex-end;
	gap: 0.75rem;
}
.modal-dialog {
	max-width: none !important;

	justify-content: center;

	.modal-body {
		min-width: 500px;

		@media (max-width: 576px) {
			min-width: 300px !important;
		}
	}
}

.docs-container {
	display: flex;
	flex-direction: column;
	align-items: center;

	.customer-docs {
		border: dashed 1px black;
		width: 124px;
		min-height: 50px;
		border-radius: 8px;
		padding: 10px;
		display: flex;
		justify-content: center;

		img {
			width: 90px;
		}

		position: relative;

		// input {
		// 	bottom: 10px;
		// 	position: absolute;
		// 	width: 104px;
		// }
		// .view-img {
		// 	position: absolute;
		// 	top: 10px;
		// }
		p {
			margin-bottom: unset;
		}
	}

	.document-action {
		display: flex;
		flex-direction: column;

		.view-img {
			margin-top: 10px;
			border-radius: 4px;
		}
	}
}

.competitor-container {
	p {
		margin-bottom: unset;
	}

	border: solid 1px rgb(232 232 232);
	border-radius: 8px;
	padding: 10px;
	margin-bottom: 10px;
}

.text-controll {
	font-size: 0.875rem;

	display: block;
	width: 100%;
	height: calc(1.5em + 1.25rem + 2px);
	padding: 0.625rem 0.75rem;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5;
	color: #8898aa;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #cad1d7;
	border-radius: 0.375rem;
	box-shadow: none;
	transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.text-area-controll {
	font-size: 0.875rem;

	display: block;
	width: 100%;
	height: calc(4.5em + 1.25rem + 2px);
	padding: 0.625rem 0.75rem;
	font-size: 0.875rem;
	font-weight: 400;
	line-height: 1.5;
	color: #8898aa;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #cad1d7;
	border-radius: 0.375rem;
	box-shadow: none;
	transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.error-msg {
	margin-top: 0.25rem;
	font-size: 80%;
	color: #fb6340;
}

.radio-wrapper {
	input {
		margin-right: 10px;
	}
}

.error-input {
	border-color: #fb6340;
}

.pagination-wrapper {
	margin-top: 10px;
	display: flex;
	justify-content: flex-end;
}

.container-line {
	width: 48%;
	min-height: 500px;
	// max-height: 500px;
	display: flex;
	justify-content: center;
	border: 1px solid rgb(223, 223, 223);
	border-radius: 10px;
}

.container-chart {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	gap: 20px;
	justify-content: space-between;
}

.chart-wrapper {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	.filter-selector {
		position: absolute;
		top: 10px;
		right: 15px;
		display: flex;
		z-index: 10;
	}

	.filter-wrapper {
		position: absolute;
		top: 10px;
		left: 15px;
		z-index: 10;
	}
}

.active-toggle {
	background-color: #e32726 !important;
	color: white !important;
}

.toggle-buttons {
	button {
		background-color: white;
		color: black;
		border: 1px solid grey;
		font-size: 12px;
		padding: 2px 6px;
		transition: background-color 0.3s ease-out;
	}

	button:hover:not(.active-toggle) {
		background-color: #f0a1a1 !important;
		color: white !important;
	}

	button:first-child {
		border-top-left-radius: 15px;
		border-bottom-left-radius: 15px;
		border-right: none;
	}

	button:last-child {
		border-top-right-radius: 15px;
		border-bottom-right-radius: 15px;
		border-left: none;
	}
}

.weekpicker-wrapper {
	input {
		padding: 1px 15px;
		border-radius: 8px;
		border: none;
	}
}

.mtd-container {
	display: flex;
	// flex-direction: column;
	flex-wrap: wrap;
	gap: 20px;
}

.number-mtd {
	border: 1px solid black;
	border-radius: 4px;
	padding: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.mtd-title {
		font-size: medium;
		font-weight: 500;
	}

	.mtd-value {
		font-size: xx-large;
		font-weight: 700;
	}
}

.modal-header {
	.close {
		padding: 0.5rem !important;
	}
}

.payment-in {
	color: green;
	font-size: 0.9rem;
	margin-bottom: unset;
}

.payment-out {
	font-size: 0.9rem;
	margin-bottom: unset;
}

.MuiFileInput-placeholder {
	color: black;
}