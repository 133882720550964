.mapContainer {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.bMapPreview {
	width: 3rem;
	height: 3rem;
	border-radius: 0.375rem;
	border: 1px solid rgba(0, 0, 0, 0.05);
	overflow: hidden;
	pointer-events: none !important;
	cursor: none !important;

	* {
		pointer-events: none !important;
		cursor: none !important;
	}
}

.pointMarker {
	width: 2px;
	height: 2px;
	background-color: black;
}