.selectCustom {
	// :hover {
	// 	background-color: aqua;
	// }
	padding: 0px 10px;
	.selectInfo {
		font-size: medium;
	}
}

.errorMessage {
	color: red;
	border-radius: 4px;
	margin-top: 0.5rem;
	opacity: 70%;
}
