.mainContainer {
	display: flex;
	width: 100%;
	height: 100vh;

	.iframe {
		width: 100%;
		height: 100%;
	}

	.splitPane {
		flex: 1;
		height: 100vh;
		max-height: 100vh;

		.splitSash {
			background-color: red;
		}

		.pane {

			// height: 100%;
			// display: flex;
			// align-items: center;
			// justify-content: center;
			// height: 100%;
			// position: absolute;
			// white-space: normal;
			// width: 100%;
			// overflow: hidden;
			&>div {
				height: 100%;
			}
		}

	}
}