.selectCustom {
	:hover {
		background-color: rgb(147, 165, 255);
	}
	padding: 0px 10px;
	border-top: 1px solid #e0e0e0;
	border-bottom: 1px solid #e0e0e0;
	.selectInfo {
		display: flex;
		justify-content: space-between;

		p {
			margin: 0;
		}
	}
	.contact {
		display: flex;
		gap: 10px;
		p {
			margin: 0;
		}
	}
}
