.iconContainer {
	width: 80px;
	height: 80px;
	position: relative;

	.overlay {
		position: absolute;
		// bottom: -1rem;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;

		.textContainer {
			// background-color: rgba(255, 255, 255, 0.5);
			padding: 0 0.5rem;
			border-radius: 0.25rem;

			p {
				margin: 0 !important;
				font-weight: 900;
				// color: rgb(255, 255, 255);
				color: black;
				--border-size: 1px;
				--border-spread: 0;
				--border-color: #FFCD03;
				text-shadow: calc(0px - var(--border-size)) var(--border-size) var(--border-spread) var(--border-color),
					var(--border-size) var(--border-size) var(--border-spread) var(--border-color),
					var(--border-size) calc(0px - var(--border-size)) var(--border-spread) var(--border-color),
					calc(0px - var(--border-size)) calc(0px - var(--border-size)) var(--border-spread) var(--border-color);
				// text-shadow: 0 0 2px white, 0 0 12px white;
				font-size: 0.8rem;
			}
		}

	}

	.iconSvg {
		transition: transform 1s linear;
	}
}