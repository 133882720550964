.mainContainer {
	display: flex;
	width: 100%;
	height: 100vh;

	.iframe {
		width: 100%;
		height: 100%;
	}

	.splitPane {
		flex: 1;
		height: 100vh;
		max-height: 100vh;

		.splitSash {
			background-color: red;
		}

		.pane {
			&>div {
				height: 100%;
			}
		}

	}
}