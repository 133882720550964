.marker {
	z-index: 2000;
}

.iconContainer {
	width: 40px;
	height: 80px;
	position: relative;

	.overlay {
		position: absolute;
		// bottom: -1rem;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;

		.textContainer {
			// background-color: rgba(255, 255, 255, 0.5);
			padding: 0 0.5rem;
			border-radius: 0.25rem;

			p {
				margin: 0 !important;
				font-weight: 900;
				// color: rgb(255, 255, 255);
				color: black;
				--border-size: 1px;
				--border-spread: 0;
				--border-color: #FED650;
				text-shadow: calc(0px - var(--border-size)) var(--border-size) var(--border-spread) var(--border-color),
					var(--border-size) var(--border-size) var(--border-spread) var(--border-color),
					var(--border-size) calc(0px - var(--border-size)) var(--border-spread) var(--border-color),
					calc(0px - var(--border-size)) calc(0px - var(--border-size)) var(--border-spread) var(--border-color);
				// text-shadow: 0 0 2px white, 0 0 12px white;
				font-size: 0.8rem;
			}
		}

	}

	.iconSvg {
		transition: transform 1s linear;
	}

	.bpContainer {
		width: 40px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.circle {
			width: 40px;
			height: 40px;
			background-color: #E42320;
			background-color: #FFCD03;
			border-radius: 40px;
			border: 2px solid white;
			border: 3px solid black;
			display: flex;
			align-items: center;
			justify-content: center;
			box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

			.siloSvg {
				width: 25px;
				height: 25px;
				color: white;
				// color: black;
			}

			.label {
				color: white;
				color: black;
				font-weight: 900;
				font-size: 0.7rem;
				letter-spacing: -1px;
			}
		}

		.line {
			width: 2px;
			height: 20px;
			// background-color: white;
			background-color: black;
			box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
		}
	}
}