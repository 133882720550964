.VehicleLiveCamera {
	background-color: white;
	border-radius: 0.5rem;
	overflow: hidden;
	padding: 0.25rem;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	display: flex;
	flex-direction: column;

	p {
		margin-bottom: 0;
	}

	.headerContainer {
		padding: 0.25rem;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.title {
			font-size: 0.8em;

		}

		.switch {
			margin: -0.5rem;
		}
	}

	.vidContainer {
		position: relative;
		border-radius: 0.25rem;
		overflow: hidden;
		width: 100%;
		aspect-ratio: 500 / 562.5;
		max-height: 100vh;

		&.vidContainerFullscreen {
			z-index: 10000;
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			background-color: rgba(0, 0, 0, 1);
		}

		.vidInner {
			flex: 1;
			width: 100%;
		}

		&:hover .overlayFullscreen {
			opacity: 1 !important;
		}

		.overlay {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 2;
			background-color: rgba(0, 0, 0, 0.25);

			&.overlayOff {
				background-color: rgba(0, 0, 0, 1);
			}

			&.overlayFullscreen {
				display: flex;
				align-items: flex-start;
				justify-content: flex-end;
				pointer-events: none;
				opacity: 0;
				background-color: rgba(0, 0, 0, 0);


				.overlayActions {
					display: flex;
					gap: 0.5rem;
					align-items: center;
					pointer-events: initial;

					.iconButton {
						opacity: 0.5;
						padding: 0.25rem;
						cursor: pointer;

						&:hover {
							opacity: 1;
						}
					}
				}
			}
		}
	}

	.reactPlayer {
		width: 100%;
		aspect-ratio: 500 / 281.25;

		&::-webkit-media-controls-timeline,
		&::-webkit-media-controls-current-time-display,
		&::-webkit-media-controls-time-remaining-display {
			display: none;
		}
	}
}