.videoContainer {
	position: relative;
	display: flex;

	.controls {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		z-index: 1;
	}

	.video {
		max-height: calc(100vh / 2);
		// width: 100%;
		aspect-ratio: 500 / 281.25;

		&::-webkit-media-controls-timeline,
		&::-webkit-media-controls-current-time-display,
		&::-webkit-media-controls-time-remaining-display,
		&::-webkit-media-controls-fullscreen-button {
			display: none;
		}

		&::-webkit-media-controls-panel {
			background-image: linear-gradient(transparent, transparent) !important; //Transparent for your case

		}
	}
}