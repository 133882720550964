.mainContent {
	transition: margin-left 0.25s ease-in-out;
	padding-bottom: 0 !important;

	&.mSidebarHidden {
		margin-left: 0 !important;
	}
}

.sidebar {
	transform: translateX(-0px);
	transition: transform 0.25s ease-in-out;

	&.sidebarHidden {
		transform: translateX(-250px);
	}
}

.sidebarToggleContainer {
	position: fixed;
	top: 0px;
	bottom: 0px;
	left: 0;
	transform: translateX(250px);
	z-index: 200000;
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: transform 0.25s ease-in-out;

	&.sidebarToggleContainerHidden {
		transform: translateX(-0px);
	}

	.toggleButton {
		width: 2rem;
		pointer-events: all;
		height: 4rem;
		background-color: rgba(227, 39, 38, 1);
		border-radius: 0 2rem 2rem 0;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		cursor: pointer;
		opacity: 0.2;
		transition: opacity 0.1s ease-in-out;

		&:hover {
			opacity: 1;
		}

		.toggleIcon {
			margin-left: -0.25rem;
			position: absolute;
			opacity: 1;
			transition: opacity 0.25s ease-in-out;

			&.toggleIconHidden {
				opacity: 0;
			}
		}
	}
}