.file-card {
	background-color: #edf2f7;
	border: 3px dashed #cbd5e0;
	padding: 1em;
	min-width: 380px;
	min-height: 230px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	.file-input {
		position: relative;
		margin-bottom: 1.5em;

		input {
			position: relative;
			max-width: 200px;
			height: 46px;
			z-index: 2;
			cursor: pointer;
			opacity: 0;
		}

		button {
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			z-index: 1;
			display: flex;
			justify-content: center;
			align-items: center;

			color: #fff;
			background-color: #f55e30;
			cursor: pointer;
			border-radius: 4px;
			border: none;
			outline: none;
			transition: background-color 0.4s;
			box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);

			i {
				width: 1.5em;
				height: 1.5em;
				padding: 0.5em;
				background-color: #fff;
				color: #f55e30;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 0.8em;
				font-size: 0.8em;
			}
		}
		&:hover {
			button {
				background-color: #f15120;
			}
		}
	}
}
