.mainContainer {
	display: flex;
	flex-direction: column;
	font-size: 0.9em;
	--color: black;

	.number {
		display: flex;
		align-items: center;
		gap: 0.25rem;
	}

	p {
		font-size: inherit !important;
		margin: 0 !important;
	}

	.lineContainer {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.headerContainer {
		font-size: 1rem;
		margin-bottom: 0.5rem;
	}

	.doContainer {}

	.switch {
		margin: -0.5rem;
	}

	.select {
		font-size: 0.7rem;
		border: none;
		background-color: #d7d7d7;
		border-radius: 0.25rem;
	}

	.displayNameContainer {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 0.25rem;

		.displayNameColor {
			width: 0.35rem;
			height: 1rem;
			background-color: var(--color);
			border-top-left-radius: 0.25rem;
			border-bottom-left-radius: 0.25rem;
		}
	}

	.link {
		color: rgba(0, 0, 0, 0.75);
		text-decoration: underline;
	}
}