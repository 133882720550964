.mainContainer {
	// color: red;
	display: flex;

	.vehiclesContainer {
		width: 300px;
		background: white;
		// box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
		box-shadow: 2px 0 12px -5px rgba(0, 0, 0, 0.1);
		padding: 1rem;
		height: 100vh;
		max-height: 100vh;
		overflow-y: auto;
		border-left: 1px solid rgba(0, 0, 0, 0.05);
		gap: 0.5rem;
		display: flex;
		flex-direction: column;

		.vehicleStatus {
			&:not(:last-child) {
				&::after {
					content: " ";
					margin-top: 0.5rem;
					width: 100%;
					height: 1px;
					background-color: rgba(0, 0, 0, 0.1);
				}
			}
		}
	}

	.splitPane {
		flex: 1;
		height: 100vh;
		max-height: 100vh;

		.splitSash {
			background-color: red;
		}

		.pane {

			// height: 100%;
			// display: flex;
			// align-items: center;
			// justify-content: center;
			// height: 100%;
			// position: absolute;
			// white-space: normal;
			// width: 100%;
			// overflow: hidden;
			&>div {
				height: 100%;
			}
		}

	}

	.camerasContainer {
		flex: 1;
		padding: 1rem;
		height: 100vh;
		max-height: 100vh;
		overflow-y: auto;
		position: relative;

		.cameraContainerOverlay {
			position: absolute;
			pointer-events: none;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 2rem;
			text-align: center;
		}

		.camerasContainerInner {
			justify-content: space-around;
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
			gap: 0.5rem;

			.camVehicle {
				// flex: 1 1 300px;

				&.camVehicleHidden {
					opacity: 0;
					z-index: -1;
					position: absolute;
					max-width: 0;
					min-width: 0;
					pointer-events: none;
				}
			}
		}
	}

	.gpsContainer {
		flex: 0.5;
	}
}