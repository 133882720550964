.vehicle-live-camera {
	video {
		width: 100%;
		aspect-ratio: 500 / 281.25;

		&::-webkit-media-controls-timeline,
		&::-webkit-media-controls-current-time-display,
		&::-webkit-media-controls-time-remaining-display {
			display: none;
		}
	}
}

.leaflet-control-container {
	display: none;
}

.visually-hidden {
	display: none;
}

.MuiButtonBase-root {
	.MuiSwitch-track {
		opacity: 1;
		transition: opacity 0.25s ease-in-out;
	}

	&.Mui-checked + .MuiSwitch-track::after {
		opacity: 0 !important;
	}

	&:not(.Mui-checked) + .MuiSwitch-track::before {
		opacity: 0 !important;
	}
}

.mapboxgl-marker {
	// transition: transform 1s ease 0s;
}

.react-split__sash {
	background-color: rgba(0, 0, 0, 0.1);

	.split-sash-content {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		&::after {
			position: absolute;
			content: "ll";
			width: 11px;
			height: 1rem;
			text-align: center;
			color: rgba(0, 0, 0, 0.5);
			letter-spacing: -1px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgb(165, 165, 165);
			border-radius: 0.1rem;
			transform: scale(1, 2);
		}

		&.split-sash-content-active::after {
			background-color: #175ede;
			color: rgba(255, 255, 255, 0.5);
		}
	}
}

input[type="date"] {
	position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: auto;
	height: auto;
	color: transparent;
	background: transparent;
}
