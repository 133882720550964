.marker {
	z-index: 1000;
}

.iconContainer {
	width: 40px;
	height: 80px;


	.iconSvg {
		transition: transform 1s linear;
	}

	.bpContainer {
		width: 40px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		.circleOuter {
			border-radius: 40px;
			background-color: white;

			.circle {
				width: 40px;
				height: 40px;
				background-color: var(--color);
				// background-color: #FFCD03;
				// background-color: #E42320;
				// background-color: white;
				border-radius: 40px;
				// border: 2px solid white;
				border: 3px solid black;
				display: flex;
				align-items: center;
				justify-content: center;
				box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
				position: relative;

				.iconSvg {
					width: 25px;
					height: 25px;
					// color: white;
					color: black;
					// color: #E42320;
				}

				.overlay {
					position: absolute;
					// bottom: -1rem;
					top: 0;
					bottom: 0;
					left: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					z-index: 2;

					.textContainer {
						// background-color: rgba(255, 255, 255, 0.5);
						// padding: 0 0.25rem;
						width: 100px;
						height: 40px;
						display: flex;
						align-items: center;

						p {
							margin: 0 !important;
							font-weight: 600;
							// color: rgb(255, 255, 255);


							word-wrap: break-word;
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 3;
							/* number of lines to show */
							line-clamp: 2;
							-webkit-box-orient: vertical;
							line-height: 12px;


							color: black;
							--border-size: 1px;
							--border-spread: 0;
							--border-color: #FED650;
							text-shadow: rgba(255, 255, 255, 0.19) 0px 10px 20px, rgba(255, 255, 255, 0.23) 0px 6px 6px;
							// text-shadow: calc(0px - var(--border-size)) var(--border-size) var(--border-spread) var(--border-color),
							// 				var(--border-size) var(--border-size) var(--border-spread) var(--border-color),
							// 				var(--border-size) calc(0px - var(--border-size)) var(--border-spread) var(--border-color),
							// 				calc(0px - var(--border-size)) calc(0px - var(--border-size)) var(--border-spread) var(--border-color);
							// text-shadow: 0 0 2px white, 0 0 12px white;
							font-size: 0.8rem;
						}
					}
				}
			}
		}

		.line {
			width: 2px;
			height: 20px;
			background-color: white;
			background-color: black;
			box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
		}
	}
}